var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", { attrs: { title: _vm.appName + " - List Services" } }),
      _c("gov-back-link", { attrs: { to: { name: "dashboard" } } }, [
        _vm._v("Back to dashboard")
      ]),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "full" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Services")
                  ]),
                  _c(
                    "gov-grid-row",
                    [
                      _c(
                        "gov-grid-column",
                        { attrs: { width: "two-thirds" } },
                        [
                          _c(
                            "ck-table-filters",
                            { on: { search: _vm.onSearch } },
                            [
                              _c(
                                "gov-form-group",
                                [
                                  _c(
                                    "gov-label",
                                    { attrs: { for: "filter[name]" } },
                                    [_vm._v("Service name")]
                                  ),
                                  _c("gov-input", {
                                    attrs: {
                                      id: "filter[name]",
                                      name: "filter[name]",
                                      type: "search"
                                    },
                                    model: {
                                      value: _vm.filters.name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "name", $$v)
                                      },
                                      expression: "filters.name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "template",
                                { slot: "extra-filters" },
                                [
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        {
                                          attrs: {
                                            for: "filter[organisation_name]"
                                          }
                                        },
                                        [_vm._v("Organisation name")]
                                      ),
                                      _c("gov-input", {
                                        attrs: {
                                          id: "filter[organisation_name]",
                                          name: "filter[organisation_name]",
                                          type: "search"
                                        },
                                        model: {
                                          value: _vm.filters.organisation_name,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "organisation_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.organisation_name"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        { attrs: { for: "filter[status]" } },
                                        [_vm._v("Status")]
                                      ),
                                      _c("gov-select", {
                                        attrs: {
                                          id: "filter[status]",
                                          name: "filter[status]",
                                          options: _vm.statuses
                                        },
                                        model: {
                                          value: _vm.filters.status,
                                          callback: function($$v) {
                                            _vm.$set(_vm.filters, "status", $$v)
                                          },
                                          expression: "filters.status"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "gov-form-group",
                                    [
                                      _c(
                                        "gov-label",
                                        {
                                          attrs: {
                                            for: "filter[referral_method]"
                                          }
                                        },
                                        [_vm._v("Referral method")]
                                      ),
                                      _c("gov-select", {
                                        attrs: {
                                          id: "filter[referral_method]",
                                          name: "filter[referral_method]",
                                          options: _vm.referralMethods
                                        },
                                        model: {
                                          value: _vm.filters.referral_method,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "referral_method",
                                              $$v
                                            )
                                          },
                                          expression: "filters.referral_method"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _vm.auth.canAdd("service")
                        ? _c(
                            "gov-grid-column",
                            { attrs: { width: "one-third" } },
                            [
                              _c(
                                "gov-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    success: "",
                                    expand: ""
                                  },
                                  on: { click: _vm.onAddService }
                                },
                                [_vm._v("Add service")]
                              ),
                              _vm.auth.canImport("services")
                                ? _c(
                                    "gov-button",
                                    {
                                      attrs: {
                                        to: { name: "services-import" },
                                        type: "submit",
                                        success: "",
                                        expand: ""
                                      }
                                    },
                                    [_vm._v("Bulk import")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("ck-resource-listing-table", {
                    ref: "servicesTable",
                    attrs: {
                      uri: "/services",
                      params: _vm.params,
                      "default-sort": "name",
                      columns: [
                        {
                          heading: "Service name",
                          sort: "name",
                          render: function(service) {
                            return service.name
                          }
                        },
                        {
                          heading: "Organisation",
                          sort: "organisation_name",
                          render: function(service) {
                            return service.organisation.name
                          }
                        },
                        {
                          heading: "Status",
                          render: function(service) {
                            return _vm.displayStatus(service.status)
                          }
                        },
                        {
                          heading: "Referral method",
                          render: function(service) {
                            return _vm.displayReferralMethod(
                              service.referral_method
                            )
                          }
                        },
                        {
                          heading: "Freshness",
                          sort: "last_modified_at",
                          render: function(service) {
                            return _vm.displayFreshness(
                              service.last_modified_at
                            )
                          }
                        }
                      ],
                      "view-route": function(service) {
                        return {
                          name: "services-show",
                          params: { service: service.id }
                        }
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }